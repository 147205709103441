import React from 'react';
import {
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Card,
  Button,
  Select,
  DatePicker,
  Checkbox,
  Typography,
  Divider,
  Col,
  Statistic
} from 'antd';
import { UserOutlined, PlusOutlined, MobileOutlined, MailOutlined, IdcardOutlined, CreditCardOutlined, EnvironmentOutlined, MinusCircleOutlined } from '@ant-design/icons';
import Link from 'antd/es/typography/Link';
const { Title, Paragraph } = Typography;


const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export const FormConfirmation: React.FC<any> = (data) => {
  
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    data.goNext();
    data.send();
  };

  const identityOptions = ["Driver's License", "Passport", "Medicare"];

  return (
  <>
    <Card>
    <Title level={2}>Confirmation Statement</Title>
      <Divider plain></Divider>
      <Paragraph>
        Please read through the information below in order to confirm that your personal data is accurate.
      </Paragraph>
    </Card>
    <Card>
      <Form
        name="calculator_form"
        initialValues={{ 
          'btc-amount': data.calculatorData.amounts.btc, 'eth-amount': data.calculatorData.amounts.eth, 'ltc-amount': data.calculatorData.amounts.ltc, 'bch-amount': data.calculatorData.amounts.bch
        }}
        disabled={true}
        labelCol={{ span: 8 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="BTC Amount">
              <Form.Item name="btc-amount" noStyle>
                <InputNumber 
                  addonAfter="BTC" 
                  min={0} 
                  style={{ width: '100%' }} 
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputNumber 
              disabled={true} 
              value={data.calculatorData.fiat.btc} 
              addonAfter="A$" 
              style={{ width: '100%' }} 

              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="ETH Amount">
              <Form.Item name="eth-amount" noStyle>
                <InputNumber 
                  addonAfter="ETH" 
                  min={0} 
                  style={{ width: '100%' }} 
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputNumber 
              disabled={true} 
              value={data.calculatorData.fiat.eth}
              addonAfter="A$" 
              style={{ width: '100%' }} 
              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="LTC Amount">
              <Form.Item name="ltc-amount" noStyle>
                <InputNumber 
                  addonAfter="LTC" 
                  min={0} 
                  style={{ width: '100%' }} 
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputNumber 
              disabled={true} 
              value={data.calculatorData.fiat.ltc}
              addonAfter="A$" 
              style={{ width: '100%' }} 

              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="BCH Amount">
              <Form.Item name="bch-amount" noStyle>
                <InputNumber 
                  addonAfter="BCH" 
                  min={0} 
                  style={{ width: '100%' }} 
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputNumber 
              disabled={true} 
              value={data.calculatorData.fiat.bch}
              addonAfter="A$" 
              style={{ width: '100%' }} 

              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={6}>
          <Statistic title="Term Selected (in months)" precision={0} value={data.calculatorData.term} suffix="Month" />
          </Col>
          <Col span={6}>
            <Statistic title="Interest Rate" value={100*Math.round(Number(data.calculatorData.rate) * 10000) / 10000} suffix="%" />
          </Col>
          <Col span={6}>
            <Statistic title="Arranger Fee" value={100*Math.round(Number(data.calculatorData.fee) * 10000) / 10000} suffix="%" />
          </Col>
          <Col span={6}>
            <Statistic title="Your LVR" value={100*Math.round(Number(data.calculatorData.lvr) * 10000) / 10000} suffix="%" />
          </Col>
        </Row>
        <br />
       
        <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Total Interest Paid (calculated upfront)">
            <InputNumber 
              disabled={true} 
              value={Math.round(Number(data.calculatorData.chargedRate) * 100) / 100}
              addonAfter="A$" 
              style={{ width: '100%' }} 
              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Total Arranger Fee">
            <InputNumber 
              disabled={true} 
              value={Math.round(Number(data.calculatorData.chargedFee) * 100) / 100}
              addonAfter="A$" 
              style={{ width: '100%' }} 
              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Total Released to You (Loan Requested)">
            <InputNumber 
              disabled={true} 
              value={Math.round(Number(data.calculatorData.desiredAmount) * 100) / 100}
              addonAfter="A$" 
              style={{ width: '100%' }} 
              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Total Repayments">
            <InputNumber 
              disabled={true} 
              value={Math.round(Number(data.calculatorData.totalValue) * 100) / 100}
              addonAfter="A$" 
              style={{ width: '100%' }} 
              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
        </Col>
      </Row>
        {/* <Statistic title="Total Repayment" value={`${Math.round(Number(data.calculatorData.totalValue + data.calculatorData.chargedRate + data.calculatorData.chargedFee) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} prefix="A$" /> */}
        
      </Form>
    </Card>
    <Card>
      <Form
        name="customer_form"
        {...formItemLayout}
        initialValues={data.customerData}
        style={{ maxWidth: 800 }}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        disabled={true}
      >
        <>
          <Divider plain><Title level={4}>Personal Information</Title></Divider>
          <Form.Item 
            label="First & middle name/s" 
            name="first_name"
            rules={[{ required: true, message: 'Please input your name' }]}
          >
            <Input prefix={<UserOutlined />}/>
          </Form.Item>
          <Form.Item 
            label="Surname" 
            name="last_name"
            rules={[{ required: true, message: 'Please input your surname' }]}
          >
            <Input prefix={<UserOutlined />}/>
          </Form.Item>
          <Form.Item 
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Please select your title' }]}
          >
            <Select>
              <Select.Option value="mr">Mr</Select.Option>
              <Select.Option value="mrs">Mrs</Select.Option>
              <Select.Option value="ms">Ms</Select.Option>
              <Select.Option value="miss">Miss</Select.Option>
              <Select.Option value="dr">Dr</Select.Option>
              <Select.Option value="hon">Hon</Select.Option>
              <Select.Option value="prof">Prof</Select.Option>
              <Select.Option value="sir">Sir</Select.Option>
              <Select.Option value="mx">Mx</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item 
            label="Gender"
            name="gender"
            rules={[{ required: true, message: 'Please select your gender' }]}
          >
            <Radio.Group>
              <Radio value="female"> Female </Radio>
              <Radio value="male"> Male </Radio>
              <Radio value="other"> Other </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item 
            label="Citizenship"
            name="citizenship"
            rules={[{ required: true, message: 'Please select your gender' }]}
          >
            <Select>
              <Select.Option value="aus_born_in_aus">Australian - Born in Australia</Select.Option>
              <Select.Option value="aus_born_overseas">Australian - Born overseas</Select.Option>
              <Select.Option value="nz_pr">New Zealand Citizen and Australian Permanent Resident</Select.Option>
              <Select.Option value="pr">Permanent Resident</Select.Option>
              <Select.Option value="other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item 
            label="Marital Status"
            name="marital_status"
            rules={[{ required: true, message: 'Please select your marital status' }]}
          >
            <Select>
              <Select.Option value="married">Married</Select.Option>
              <Select.Option value="single">Single</Select.Option>
              <Select.Option value="divorced">Divorced</Select.Option>
              <Select.Option value="separated">Separated</Select.Option>
              <Select.Option value="widowed">Widowed</Select.Option>
              <Select.Option value="defactor">Defacto</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item 
            label="Date of birth"
            name="date_birth"
            rules={[{ required: true, message: 'Please select your date of birth' }]}
          >
            <DatePicker style={{width:'100%'}} />
          </Form.Item>
        </>

        <>
          <Divider plain><Title level={4}>Contact Information</Title></Divider>
          <Form.Item 
            label="Mobile number" 
            name="mobile_number"
            rules={[{ required: true, message: 'Please input your mobile number' }]}
          >
            <Input prefix={<MobileOutlined />}/>
          </Form.Item>
          <Form.Item 
            label="Email" 
            name="email"
            rules={[{ required: true, message: 'Please input your email' }]}
          >
            <Input prefix={<MailOutlined />}/>
          </Form.Item>
        </>

        <Divider plain><Title level={4}>Identification</Title></Divider>
        <Form.Item 
          // label="First & middle name/s" 
          name="identity"
          rules={[{ required: true, message: 'Please select at least one identity' }]}
        >
        <Checkbox.Group
          options={identityOptions}
          // onChange={(e) => { setIdentityTypes(e);console.log(e) }}
        >
        </Checkbox.Group>
        </Form.Item>

        { data.customerData.identity.includes("Driver's License") ? (
          <>
            <Divider plain><Title level={5}>Driver's License</Title></Divider>
            <br />
            <br />
            <Form.Item 
              name="state"
              label="State"
              rules={[{ required: true, message: 'Please input your state' }]}
            >
              <Select>
                <Select.Option value="act">ACT</Select.Option>
                <Select.Option value="nsw">NSW</Select.Option>
                <Select.Option value="nt">NT</Select.Option>
                <Select.Option value="qld">QLD</Select.Option>
                <Select.Option value="sa">SA</Select.Option>
                <Select.Option value="tas">TAS</Select.Option>
                <Select.Option value="vic">VIC</Select.Option>
                <Select.Option value="wa">WA</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item 
              label="License number" 
              name="license_number"
              rules={[{ required: true, message: 'Please input your license' }]}
            >
              <Input prefix={<IdcardOutlined />}/>
            </Form.Item>
            <Form.Item 
              label="Card number" 
              name="card_number"
              rules={[{ required: true, message: 'Please input your license' }]}
            >
              <Input prefix={<CreditCardOutlined />}/>
            </Form.Item>
          </>
          )
        : <></> }

        { data.customerData.identity.includes("Passport") ? (
          <>
            <Divider plain><Title level={5}>Passport</Title></Divider>
            <br />
            <br />
            <Form.Item 
              label="Number" 
              name="passport_number"
              rules={[{ required: true, message: 'Please input your passport number' }]}
            >
              <Input prefix={<IdcardOutlined />}/>
            </Form.Item>
            <Form.Item 
              label="Name" 
              name="passport_name"
              rules={[{ required: true, message: 'Please input your passport name' }]}
            >
              <Input prefix={<UserOutlined />}/>
            </Form.Item>
            <Form.Item 
              label="Issued on" 
              name="passport_issued"
              rules={[{ required: true, message: 'Please input your passport issuance date' }]}
            >
              <DatePicker style={{width:'100%'}} />
            </Form.Item>
            <Form.Item 
              label="Expires on" 
              name="passport_expires"
              rules={[{ required: true, message: 'Please input your passport expiry date' }]}
            >
              <DatePicker style={{width:'100%'}} />
            </Form.Item>
            <Form.Item 
              label="Nationality" 
              name="nationality"
              rules={[{ required: true, message: 'Please input your nationality' }]}
            >
              <Input prefix={<UserOutlined />}/>
            </Form.Item>
            <Form.Item 
              label="Place of issue" 
              name="place_of_issue"
              rules={[{ required: true, message: 'Please input your passport place of issue' }]}
            >
              <Input prefix={<UserOutlined />}/>
            </Form.Item>
          </>
        )
        : <></>}

        { data.customerData.identity.includes("Medicare") ? (
          <>
            <Divider plain><Title level={5}>Medicare</Title></Divider>
            <br />
            <br />
            <Form.Item 
              label="Medicare number" 
              name="medicare_number"
              rules={[{ required: true, message: 'Please input your medicare number' }]}
            >
              <Input prefix={<IdcardOutlined />}/>
            </Form.Item>
            <Form.Item 
              label="Medicare card position" 
              name="medicare_position"
              rules={[{ required: true, message: 'Please input your medicare position' }]}
            >
              <Select>
                <Select.Option value="1">1</Select.Option>
                <Select.Option value="2">2</Select.Option>
                <Select.Option value="3">3</Select.Option>
                <Select.Option value="4">4</Select.Option>
                <Select.Option value="5">5</Select.Option>
                <Select.Option value="6">6</Select.Option>
                <Select.Option value="7">7</Select.Option>
                <Select.Option value="8">8</Select.Option>
                <Select.Option value="9">9</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item 
              label="Medicare card color" 
              name="medicare_color"
              rules={[{ required: true, message: 'Please input your medicare color' }]}
            >
              <Select>
                  <Select.Option value="green">Green - Australian Resident</Select.Option>
                  <Select.Option value="blue">Blue - Interim Card</Select.Option>
                  <Select.Option value="yellow">RHCA (Reciprocal Health Care Agreement)</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item 
              label="Expires on" 
              name="medicare_expires"
              rules={[{ required: true, message: 'Please input your medicare expiry date' }]}
            >
              <DatePicker style={{width:'100%'}} />
            </Form.Item>
          </>
        )
        : <></>}

        <Divider plain><Title level={4}>Residential Address</Title></Divider>
        <Form.Item 
          label="Unit / suite / level" 
          name="residential_unit_suite_level"
          rules={[{ required: true, message: 'Please input your unit.' }]}
        >
          <Input prefix={<EnvironmentOutlined />}/>
        </Form.Item>
        <Form.Item 
          label="Street number & address" 
          name="residential_number_address"
          rules={[{ required: true, message: 'Please input your address.' }]}
        >
          <Input prefix={<EnvironmentOutlined />}/>
        </Form.Item>

        <Divider plain><Title level={4}>Current Employment</Title></Divider>
        <Form.Item 
          label="Employment type" 
          name="employment_type"
          rules={[{ required: true, message: 'Please input your employment type.' }]}
        >
          <Select>
            <Select.Option value="full_time">Full Time</Select.Option>
            <Select.Option value="part_time">Part Time</Select.Option>
            <Select.Option value="casual">Casual / Contract / Tem</Select.Option>
            <Select.Option value="self_employed">Self Employed</Select.Option>
            <Select.Option value="retired">Retired</Select.Option>
            <Select.Option value="pensioner">Pensioner</Select.Option>
            <Select.Option value="student">Student</Select.Option>
            <Select.Option value="workers_compensation">Worker's Compensation</Select.Option>
            <Select.Option value="centrelink">Centrelink</Select.Option>
            <Select.Option value="carer">Carer</Select.Option>
            <Select.Option value="veteran">Department of Veternas Affairs Pension</Select.Option>
          </Select>
        </Form.Item>

        <Divider plain><Title level={4}>Current Employment Incone</Title></Divider>
        <Form.Item 
          label="Income after tax" 
          name="income_after_tax"
          rules={[{ required: true, message: 'Please input your income.' }]}
        >
          <InputNumber 
            addonAfter="A$" 
            style={{ width: '100%' }} 

            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Form.Item>
        <Form.Item 
          label="Income frequency" 
          name="income_frequency"
          rules={[{ required: true, message: 'Please input your income frequency.' }]}
        >
          <Select>
            <Select.Option value="weekly">Weekly</Select.Option>
            <Select.Option value="fortnightly">Fortnightly</Select.Option>
            <Select.Option value="monthly">Monthly</Select.Option>
          </Select>
        </Form.Item>
        
        {data.customerData.additional_income ? (<>
        <Divider plain><Title level={4}>Additional Income</Title></Divider>
        <Form.List 
          name="additional_income"
        >
          {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key} wrap={false} gutter={[10, 10]} align="middle" justify="space-between">
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    name={[name, 'additional_income_type']}
                    rules={[{ required: true, message: 'Missing first name' }]}
                  >
                    <Select
                      placeholder="Select an income type"
                      style={{ width: '100%' }} 
                    >
                      <Select.Option value="board">Board</Select.Option>
                      <Select.Option value="carer">Carer</Select.Option>
                      <Select.Option value="casual">Casual work</Select.Option>
                      <Select.Option value="centrelink">Centrelink</Select.Option>
                      <Select.Option value="child_support">Child Support</Select.Option>
                      <Select.Option value="commissions">Commissions / Allowances</Select.Option>
                      <Select.Option value="veteran">Department of Veterans Affairs Pension</Select.Option>
                      <Select.Option value="disability">Disability Support Pension</Select.Option>
                      <Select.Option value="family">Family Allowance</Select.Option>
                      <Select.Option value="full_time">Full-time work</Select.Option>
                      <Select.Option value="interests">Interest / Dividends</Select.Option>
                      <Select.Option value="parenting">Parenting Payment</Select.Option>
                      <Select.Option value="part_time">Part-time work</Select.Option>
                      <Select.Option value="pension">Pension</Select.Option>
                      <Select.Option value="regular_overtime">Regular overtime</Select.Option>
                      <Select.Option value="rent_received">Rent received</Select.Option>
                      <Select.Option value="self_employed">Self Employed</Select.Option>
                      <Select.Option value="other_income">Other Income</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...restField}
                    name={[name, 'frequency']}
                    rules={[{ required: true, message: 'Missing first name' }]}
                  >
                    <Select
                      placeholder="Select an income frequency"
                      style={{ width: '100%' }} 
                    >
                      <Select.Option value="weekly">Weekly</Select.Option>
                      <Select.Option value="fortnightly">Fortnightly</Select.Option>
                      <Select.Option value="monthly">Monthly</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    {...restField}
                    name={[name, 'additional_income_amount']}
                    rules={[{ required: true, message: 'Missing last name' }]}
                  >
                    <InputNumber 
                      addonAfter="A$" 
                      style={{width:'100%'}}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    />
                  </Form.Item>                
                </Col>
                <Col span={1}>
                  <Form.Item
                    {...restField}
                    name={[name, 'additional_income_amount']}
                    rules={[{ required: true, message: 'Missing last name' }]}
                  >
                    <MinusCircleOutlined
                      onClick={() => remove(name)} />
                  </Form.Item>                
                </Col>
              </Row>
            ))}
            <Row wrap={true} gutter={[10, 10]} align="middle" justify="space-between">
            <Col span={24}>
              <Form.Item
                // label="" 
              >
                {/* <Col span={12} offset={6}> */}
                <Button 
                  type="dashed" 
                  onClick={() => add()} 
                  style={{width:"100%"}} 
                  // style={{ display: 'flex', marginBottom: 8 }} 
                  icon={<PlusOutlined />}
                >
                  Add Income
                </Button>
                {/* </Col> */}
              </Form.Item>
              </Col>
            </Row>
          </>
          )}
        </Form.List>
        </>) : <></>}

        <Divider plain><Title level={4}>General Living Expenses</Title></Divider>
        <Form.Item 
          label="Total expenses" 
          name="total_expenses"
          rules={[{ required: true, message: 'Please input your total expenses.' }]}
        >
          <InputNumber 
            addonAfter="A$" 
            style={{ width: '100%' }} 

            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Form.Item>
        <Form.Item 
          label="Expenses frequency" 
          name="total_frequency"
          rules={[{ required: true, message: 'Please input your expenses frequency.' }]}
        >
          <Select>
            <Select.Option value="weekly">Weekly</Select.Option>
            <Select.Option value="fortnightly">Fortnightly</Select.Option>
            <Select.Option value="monthly">Monthly</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Card>
    <Card>
      <Title level={4}>Privacy & Confirmation</Title>
      <Divider plain></Divider>
      <Paragraph>
        Please read our <Link href="https://bit.ly/B4FinancePrivacyPolicy">Privacy Policy</Link>
      </Paragraph>
      <Form
        name="confirmation_form"
        onFinish={onFinish}
        >
        <Form.Item
          name="privacy"
          valuePropName="checked"
          rules={[{ 
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Please confirm that you have read the privacy policy.')),
          }]}
        >
          <Checkbox>Hereby I confirm that I have read the privacy policy.</Checkbox>
        </Form.Item>
      
        <Divider plain></Divider>
        <Paragraph>
          Please confirm that you have not been declared bankrupt
        </Paragraph>
        <Form.Item
          name="bankrupt"
          valuePropName="checked"
          rules={[{ 
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Please confirm.')),
          }]}
        >
          <Checkbox>I confirm that I am not bankrupt or currently involved in a Part IX or X debt agreement</Checkbox>
        </Form.Item>

        <Divider plain></Divider>
        <Paragraph>
          Please read through the information below in order to confirm that your personal data is accurate.
        </Paragraph>
        <Form.Item
          name="confirmation"
          valuePropName="checked"
          rules={[{ 
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Please confirm accuracy.')),
          }]}
        >
          <Checkbox>Hereby I confirm the information is accurate</Checkbox>
        </Form.Item>
        
        <Form.Item>
          <Button 
            style={{
              position: "absolute",
              width: "120px",
              left: 0,
              transform: "translateY(-50%)"
            }} 
            onClick={() => data.goPrevious()}
            disabled={false}
          >
              Previous
          </Button>
          <Button 
            style={{
              position: "absolute",
              right: 0,
              width: "120px",
              transform: "translateY(-50%)"
            }} 
            type="primary" 
            disabled={false}
            htmlType="submit"
          >
              Next
          </Button>
        </Form.Item>
      </Form>
    </Card>
  </>
)};