import React, { useState, useEffect } from 'react';
import './index.css';
import { Col, Layout, Steps, theme, Result, Button, Card, Typography, Divider, notification } from 'antd';

import { FormCalculator } from './components/calculator'
import { FormCustomer } from './components/customer'
import { FormConfirmation } from './components/confirmation'

import emailjs from '@emailjs/browser';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';


const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;

const emailjs_service_id = 'service_kc8h6su';
const emailjs_template_id = 'template_6ytp03m';
const emailjs_template_public_key = '6avSaj183b8K9nJdN';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
          setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  return (width < 768);
}



const App: React.FC = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [calculatorData, setCalculatorData] = useState<any>({});
  const [customerData, setCustomerData] = useState<any>({});

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description
    });
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const captureCalculatorData = (data: any) => {
    setCalculatorData(data);
  }
  
  const captureCustomerData = (data: any) => {
    setCustomerData(data);
    next();
  }

  const send = () => {
    emailjs.send(emailjs_service_id, emailjs_template_id, {
      message: JSON.stringify({ calc: calculatorData, personal: customerData}, null, 4)

    } , emailjs_template_public_key)
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });

    axios
    .post(
      "https://nc-cors.azurewebsites.net/api/hubspot-b4finance-calculator?code=lfwXBr8rfYnIUq7esa-AjD2BlFtHKy-oksP_jQvrXnG5AzFuPOA_Rw==", 
      { calc: calculatorData, personal: customerData}, 
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
    )
    .then((val) => { 
      // console.log(val);
      
    })
    .catch(err => {
      // console.log(err)
      openNotificationWithIcon('error', "Error", "Error submitting message, please contact B4Finance");
    });

  }

  const isMobile = useCheckMobileScreen()
  const spanValue = isMobile ? 24 : 16;
  const offsetValue = isMobile ? 0 : 4;

  const steps = [
    {
      title: 'Start',
      content: 
        <Col span={spanValue} offset={offsetValue}>
          <Card>
            <Title level={2}>Welcome to the B4Finance Pre-Application Calculator!</Title>
            <Divider plain></Divider>
            <Paragraph style={{textAlign:'left'}}>
            On the next page, you can use our obligation-free loan calculator to check to see how much you are able to borrow against your digital assets. If you choose to proceed further and receive a formal loan offer, please make sure that all details are correct.
            </Paragraph>
            <Paragraph  style={{textAlign:'left'}}>
            This is an indicative quote only, based on live crypto pricing data, and is not to be considered binding by any party. Final collateral valuation will be subject to price data at the time of collateralisation. Minimum loan facility $20,000 AUD. All digital asset-backed loans are exclusively offered by Pegasus Capital Partners.
            </Paragraph>
            <Divider plain></Divider>
            <Button 
              style={{
                position: "absolute",
                right: 20,
                width: "120px",
                transform: "translateY(-50%)"
              }} 
              type="primary" 
              onClick={next}
            >
                Next
            </Button>
          </Card>
        </Col>,
    },
    {
      title: 'Calculator',
      content: <Col span={spanValue} offset={offsetValue}><FormCalculator isMobile={isMobile} goPrevious={prev} goNext={next} setCalculatorData={captureCalculatorData} /></Col>,
    },
    {
      title: 'Personal Data',
      content: <Col span={spanValue} offset={offsetValue}><FormCustomer goPrevious={prev} setCustomerData={captureCustomerData} customerData={customerData} /></Col>,
    },
    {
      title: 'Confirmation',
      content: <Col span={spanValue} offset={offsetValue}><FormConfirmation goNext={next} goPrevious={prev} customerData={customerData} calculatorData={calculatorData} send={send} /></Col>,
    },
    {
      title: 'Done',
      content: 
        <Col span={spanValue} offset={offsetValue}><Result
          status="success"
          title="Successfully submitted your request!"
        />
            {/* <Divider plain></Divider>
            <Button 
              style={{
                position: "absolute",
                left: 0,
                width: "120px",
                transform: "translateY(-50%)"
              }} 
              type="primary" 
              onClick={prev}
            >
                Back
            </Button> */}
        </Col>,
    },
  ];
  

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <>
      <Layout className="layout">
        <Header>
          <img 
            src='../Logo_mark.png'
            style={{ 
              marginTop:10,
              height: '40px'
            }}
          >
          </img>
          <img 
            src='../Logo_name.png'
            style={{ 
              marginBottom:10,
              marginLeft:5,
              height: '20px'
            }}
          >
          </img>
        </Header>
        <Content style={{ padding: isMobile ? '' : '0 50px' }}>
          <br></br>
          <Steps current={current} items={items} />
          <div style={contentStyle}>{steps[current].content}</div>
        </Content>
      </Layout>
    </>
  );
};

export default App;