import React, { useState } from 'react';
import {
  Col,
  Form,
  InputNumber,
  Radio,
  Row,
  Statistic,
  Card,
  message,
  Button,
  Divider,
  Typography
} from 'antd';

import axios from 'axios';

const { Title, Paragraph } = Typography;

const termStructure = {
  12: {
    rate: 0.07,//0.0625,
    lvr: 0.7//0.6
  },
  // 18: {
  //   rate: 0.0575,
  //   lvr: 0.65
  // },
  24: {
    rate: 0.0625,//0.0535,
    lvr: 0.7
  },
  36: {
    rate: 0.055,//0.0495,
    lvr: 0.72//0.75
  },
  48: {
    rate: 0.045,
    lvr: 0.75
  }
}


export const FormCalculator: React.FC<any> = (data) => {
  const [btcAmount, setBTCAmount] = useState(0);
  const [ethAmount, setETHAmount] = useState(0);
  const [ltcAmount, setLTCAmount] = useState(0);
  const [bchAmount, setBCHAmount] = useState(0);

  const [pkg, setPkg] = useState<any>({});

  const [btcFX, setBTCFX] = useState(1000);
  const [ethFX, setETHFX] = useState(10000);
  const [ltcFX, setLTCFX] = useState(100000);
  const [bchFX, setBCHFX] = useState(1000000);

  const [desiredAmount, setDesiredAmount] = useState(100000);

  const [btcFiat, setBTCFiat] = useState(1);
  const [ethFiat, setETHFiat] = useState(1);
  const [ltcFiat, setLTCFiat] = useState(1);
  const [bchFiat, setBCHFiat] = useState(1);

  const [term, setTerm] = useState(12);
  const [rate, setRate] = useState(termStructure[12].rate);
  const [lvr, setLVR] = useState(termStructure[12].lvr);
  const [fee, setFee] = useState(0.025);

  let _lvr = termStructure[12].lvr;
  let _rate = termStructure[12].rate;
  let value = desiredAmount;

  const [chargedRate, setChargedRate] = useState(value * _rate * term / 12);
  const [chargedFee, setChargedFee] = useState(value * fee);

  const update = (desiredAmount: number, term: number) => {
    // let pkg = getPkg(btcFiat + ethFiat + ltcFiat + bchFiat, term, fee);
    let pkg = getPkg(desiredAmount, term, fee);
    data.setCalculatorData(pkg);
  };

  const onFinish = (values: any) => {    
    update(desiredAmount, term);
    data.goNext();
  };

  const getPkg = (value : number, term: number, fee: number) => {
    // if(term === 12 || term === 18 || term === 24 || term === 36 || term === 48){
      if(term === 12 || term === 24 || term === 36 || term === 48){
      let _lvr = 1;//termStructure[term].lvr;
      let _rate = termStructure[term].rate;
      let chargedRate = value * _lvr * _rate * term / 12;
      let chargedFee = value * _lvr * fee;
      let availableAmount = value * _lvr;
      let totalValue = availableAmount + chargedFee + chargedRate;
      setChargedFee(value * _lvr * fee);
      setChargedRate(value * _lvr * _rate * term / 12);
      let pkg = {
        term: term,
        rate: _rate,
        fee: fee,
        lvr: termStructure[term].lvr,
        amounts: {
          btc: btcAmount,
          eth: ethAmount,
          ltc: ltcAmount,
          bch: bchAmount
        },
        fx: {
          btc: btcFX,
          eth: ethFX,
          ltc: ltcFX,
          bch: bchFX
        },
        fiat: {
          btc: btcFiat,
          eth: ethFiat,
          ltc: ltcFiat,
          bch: bchFiat
        },
        chargedFee: chargedFee,
        chargedRate: chargedRate,
        totalValue: totalValue,
        desiredAmount: value
      }
      return pkg;      
    }
    return {};
  }
  
  const totalAvailableAmount = (value : number, term: number, fee: number) : number => {
    // if(term === 12 || term === 18 || term === 24 || term === 36 || term === 48){
    if(term === 12 || term === 24 || term === 36 || term === 48){
      let _lvr = 1//termStructure[term].lvr;
      let _rate = termStructure[term].rate;
      
      let chargedRate = value * _lvr* _rate * term / 12;
      let chargedFee = value * _lvr* fee;
      // let availableAmount = value * _lvr;
      // let totalValue = availableAmount - chargedFee - chargedRate;
      let totalValue = value + chargedFee + chargedRate;

      // setChargedRate(value * _lvr * fee);
      // setChargedFee(value * _lvr * _rate * term / 12);
      
      return totalValue;
    }
    return 0;
  }

  if(btcFiat === 1){
    axios
    .get(
      "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Clitecoin%2Cbitcoin-cash&vs_currencies=aud", {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      }
    })
    .then((val) => { 
      setBTCFX(val.data['bitcoin'].aud);
      setETHFX(val.data['ethereum'].aud);
      setLTCFX(val.data['litecoin'].aud);
      setBCHFX(val.data['bitcoin-cash'].aud);

      setBTCFiat(btcAmount * val.data['bitcoin'].aud);
      setETHFiat(ethAmount * val.data['ethereum'].aud);
      setLTCFiat(ltcAmount * val.data['litecoin'].aud);
      setBCHFiat(bchAmount * val.data['bitcoin-cash'].aud);
    })
    .catch(err => {
      message.error('Error connecting to market data!');
    })
  }
  return (
  <Card>
    
    <br></br>
    <Form
      name="calculator_form"
      onFinish={onFinish}
      initialValues={{ 
        'desired_amount': desiredAmount, 'btc-amount': 0, 'eth-amount': 0, 'ltc-amount': 0, 'bch-amount': 0, 'term': '12'
      }}
      labelCol={{ span: 8 }}
    >
      <Paragraph style={{textAlign:'left'}} strong>
      Step One: enter your requested loan amount and length:
      </Paragraph>
      <Divider plain></Divider>

      <Row gutter={16}>
        <Col span={10}>
          <Form.Item 
            label="Loan Requested" 
            name="desired_amount"
            rules={[{ required: true, message: 'Please input request' }]}
          >
            <InputNumber 
                addonAfter="A$" 
                min={0} 
                style={{ width: '100%' }} 

                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onChange={(value) => {if(value) {setDesiredAmount(value); update(value, term); } }}
              />
          </Form.Item>
        </Col>
        <Col span={14}>
        <Form.Item
            // style={{width:'100%'}}
            name="term"
            label="Term Selected (in months): "
            rules={[{ required: true, message: 'Please pick a term' }]}
          >
            <Radio.Group
              style={{width:'100%'}}
              onChange={(value) => { 
                let val = Number(value.target.value); 
                // if (val === 12 || val === 18 || val === 24 || val === 36 || val === 48) { 
                if (val === 12 || val === 24 || val === 36 || val === 48) { 
                  setRate(termStructure[val].rate); 
                  setLVR(termStructure[val].lvr); 
                  setTerm(val);
                  
                  let _lvr = 1.0//termStructure[val].lvr;
                  let _rate = termStructure[val].rate;
                  // let value = btcFiat + ethFiat + ltcFiat + bchFiat;
                  let value = desiredAmount;
                  let chargedRate = value * _lvr * _rate * val / 12;
                  let chargedFee = value * _lvr * fee;
                  console.log('chargedRate', value , _lvr , _rate , val / 12, chargedRate);
                  console.log('chargedFee', value , _lvr , fee, chargedFee);
                  setChargedFee(chargedFee);
                  setChargedRate(chargedRate);
                }
              }}
            >
              <Radio.Button value="12">12</Radio.Button>
              {/* <Radio.Button value="18">18</Radio.Button> */}
              <Radio.Button value="24">24</Radio.Button>
              <Radio.Button value="36">36</Radio.Button>
              <Radio.Button value="48">48</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item 
            label="Total Collateral Required"
          >
            <InputNumber 
                addonAfter="A$" 
                // min={0} 
                style={{ width: '100%' }} 
                value={desiredAmount / lvr + desiredAmount * (rate + fee) * term / 12}
                
                disabled={true}
                formatter={(value) => `${Math.round(Number(value) * 100)/100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
          </Form.Item>
        </Col>
      </Row>
      <br />
      <Divider plain></Divider>
      <Paragraph style={{textAlign:'left'}} strong>
      Step Two: select digital assets available to collateralise:
      </Paragraph>
      <Divider plain></Divider>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Remaining Collateral Required">
          {data.isMobile ? <><br /><br /></> : <></>}
            <InputNumber 
              disabled={true} 
              value={Math.max(0, Math.round(Number((desiredAmount / lvr + desiredAmount * (rate + fee) * term / 12) - (btcFiat + ethFiat + ltcFiat + bchFiat)) * 100) / 100)} 
              addonAfter="A$" 
              style={{ width: '100%' }} 
              min={desiredAmount / lvr + desiredAmount * (rate + fee) * term / 12} 

              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="BTC Amount">
            <Form.Item name="btc-amount" noStyle>
              <InputNumber 
                addonAfter="BTC" 
                min={0} 
                style={{ width: '100%' }} 

                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onChange={(value) => { if(value != undefined) { setBTCAmount(value);  setBTCFiat(value * btcFX); } }}
              />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={12}>
          {data.isMobile ? <><br /><br /></> : <></>}
          <InputNumber 
            disabled={true} 
            value={btcFiat} 
            addonAfter="A$" 
            style={{ width: '100%' }} 

            formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="ETH Amount">
            <Form.Item name="eth-amount" noStyle>
              <InputNumber 
                addonAfter="ETH" 
                min={0} 
                style={{ width: '100%' }} 

                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onChange={(value) => { if(value != undefined) { setETHAmount(value);  setETHFiat(value * ethFX); } }}
              />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={12}>
          {data.isMobile ? <><br /><br /></> : <></>}
          <InputNumber 
            disabled={true} 
            value={ethFiat} 
            addonAfter="A$" 
            style={{ width: '100%' }} 

            formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="LTC Amount">
            <Form.Item name="ltc-amount" noStyle>
              <InputNumber 
                addonAfter="LTC" 
                min={0} 
                style={{ width: '100%' }} 

                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onChange={(value) => { if(value != undefined) { setLTCAmount(value);  setLTCFiat(value * ltcFX); } }}
              />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={12}>
          {data.isMobile ? <><br /><br /></> : <></>}
          <InputNumber 
            disabled={true} 
            value={ltcFiat} 
            addonAfter="A$" 
            style={{ width: '100%' }} 

            formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="BCH Amount">
            <Form.Item name="bch-amount" noStyle>
              <InputNumber 
                addonAfter="BCH" 
                min={0} 
                style={{ width: '100%' }} 

                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onChange={(value) => { if(value != undefined) { setBCHAmount(value);  setBCHFiat(value * bchFX); } }}
              />
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={12}>
          {data.isMobile ? <><br /><br /></> : <></>}
          <InputNumber 
            disabled={true} 
            value={bchFiat} 
            addonAfter="A$" 
            style={{ width: '100%' }} 

            formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Total Collateral Provided:">
          <InputNumber 
            disabled={true} 
            value={btcFiat + ethFiat + ltcFiat + bchFiat} 
            addonAfter="A$" 
            style={{ width: '100%' }} 

            formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
          </Form.Item>

        </Col>
      </Row>

      <Divider plain></Divider>
      <Paragraph style={{textAlign:'left'}} strong>
      Your Offer:
      </Paragraph>
      <Divider plain></Divider>

      

      <Row gutter={16}>
        <Col span={8}>
          <Statistic title="Interest Rate" value={100*Math.round(Number(rate) * 10000) / 10000} suffix="%" />
        </Col>
        <Col span={8}>
          <Statistic title="Arranger Fee" value={100*Math.round(Number(fee) * 10000) / 10000} suffix="%" />
        </Col>
        <Col span={8}>
          <Statistic title="Your LVR" value={100*Math.round(Number(lvr) * 10000) / 10000} suffix="%" />
        </Col>
      </Row>
      
      <br />
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Total Interest Paid (calculated upfront)">
            <InputNumber 
              disabled={true} 
              value={Math.round(Number(chargedRate) * 100) / 100}
              addonAfter="A$" 
              style={{ width: '100%' }} 
              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Total Arranger Fee">
            <InputNumber 
              disabled={true} 
              value={Math.round(Number(chargedFee) * 100) / 100}
              addonAfter="A$" 
              style={{ width: '100%' }} 
              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Total Released to You (Loan Requested)">
            <InputNumber 
              disabled={true} 
              value={Math.round(Number(desiredAmount) * 100) / 100}
              addonAfter="A$" 
              style={{ width: '100%' }} 
              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Total Repayments">
            <InputNumber 
              disabled={true} 
              value={Math.round(Number(totalAvailableAmount(desiredAmount, term, fee)) * 100) / 100}
              addonAfter="A$" 
              style={{ width: '100%' }} 
              formatter={(value) => `${Math.round(Number(value) * 100) / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
        </Col>
      </Row>

      <br />
      <Divider plain></Divider>
      <Paragraph style={{textAlign:'left'}}>
      Remember – there are no periodic repayments with this loan. A single repayment of ${totalAvailableAmount(desiredAmount, term, fee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} is due in {term} months to settle the loan and release your digital collateral.
      To proceed with this application, click Next.

      </Paragraph>
      <Divider plain></Divider>

      <Form.Item>
        <Button 
            style={{
              position: "absolute",
              width: "120px",
              left: 0,
              transform: "translateY(-50%)"
            }} 
            onClick={() => data.goPrevious()}
            disabled={false}
          >
              Previous
          </Button>
        <Button 
          style={{
            position: "absolute",
            right: 0,
            width: "120px",
            transform: "translateY(-50%)"
          }} 
          type="primary" 
          htmlType="submit"
        >
            Next
        </Button>
      </Form.Item>
    </Form>
  </Card>
)};